import './App.css';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import ConsentBar from './components/consent-bar';
import Footer from './components/footer/Footer';
import HeroPaneAbout from './components/hero-pane-about';
import LogoBanner from './components/logo';
import NavBar from './components/nav-bar';
import NewsBar from './components/news-bar';
import { theme } from './components/theme';
import Routes from './routes';
import useTracker from './shared/tracker';

function App() {
  useTracker();

  // set the context context as false as standard - for now the feature is turned off
  // eslint-disable-next-line
  const [darkMode, setDarkMode] = useState<boolean>(false);

  return (
    <div style={{ flexGrow: 1 }} className="App">
      <ThemeProvider theme={theme(darkMode)}>
        <CssBaseline />
        <ConsentBar />
        <HelmetProvider>
          <Helmet
            titleTemplate="%s - Serverless Advocate"
            defaultTitle="Serverless Advocate"
          >
            <meta name="description" content="App" />
          </Helmet>
          <Grid
            container
            columns={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            style={{ width: '100%' }}
          >
            <Grid item xs={12} sm={12} md={12} style={{ width: '100%' }}>
              <NewsBar />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ width: '100%' }}>
              <NavBar />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ width: '100%' }}>
              <LogoBanner />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ width: '100%' }}
              minHeight={1000}
            >
              <Routes />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ width: '100%' }}>
              <HeroPaneAbout />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ width: '100%' }}>
              <Footer />
            </Grid>
          </Grid>
        </HelmetProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
