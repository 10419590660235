import {
  AppBar,
  Box,
  Grid,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';

const NewsBar = () => {
  const theme = useTheme();
  const backgroundColor = theme.palette.background.default;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        variant="outlined"
        sx={{ backgroundColor: backgroundColor }}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Typography variant="body2" color={'primary'}>
                <TipsAndUpdatesTwoToneIcon fontSize="small" /> <b>Sponsor</b> my
                blog & website? Contact me!
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NewsBar;
